<template>
  <div class="government">
    <div class="government__header relative py-20 bg-cover mdmax:px-4 mdmax:py-10 mdmax:bg-right">
      <div class="container">
        <div class="relative z-1 text-white text-4xl font-bold mdmax:text-2xl mb-4">Pemerintah</div>
        <div class="relative z-1 text-white text-2xl max-w-3xl mdmax:text-base">
          Negara yang maju adalah negara yang mampu menguasai teknologi. Kami hadir untuk menjadi mitra mewujudkan negara yang maju, serta menghasilkan SDM unggul! G2 Academy memiliki bidang khusus
          yang menangani kerjasama antara perusahaan dengan pemerintah yang akan mendatangkan keuntungan bersama bagi bangsa dan negara Indonesia
        </div>
      </div>
    </div>
    <div class="py-10 mdmax:px-4">
      <div class="container">
        <h2 class="text-2xl font-semibold text-center mdmax:text-2xl">Kerjasama G2 Academy dan Pemerintah</h2>
        <div class="flex gap-10 items-stretch mb-20 mt-10 justify-between mdmax:flex-col mdmax:gap-4 mdmax:mb-10">
          <div class="flex-1 border p-6 rounded text-center bg-blue text-white">
            <h2 class="text-lg font-semibold flex justify-center items-center h-full">Digital Transformation</h2>
          </div>
          <div class="flex-1 border p-6 rounded text-center bg-blue text-white">
            <h2 class="text-lg font-semibold flex justify-center items-center h-full">Training & Professional Development</h2>
          </div>
          <div class="flex-1 border p-6 rounded text-center bg-blue text-white">
            <h2 class="text-lg font-semibold flex justify-center items-center h-full">Project dan Infrastruktur</h2>
          </div>
        </div>
        <div class="text-center mb-20">
          <router-link class="text-white border inline-block cursor-pointer border-yellow bg-yellow px-4 py-2 rounded font-semibold mt-4" to="/contact">Hubungi Kami</router-link>
        </div>
        <div class="flex gap-10 items-center mb-20 mdmax:flex-col mdmax:mb-20">
          <div class="w-3/5 mdmax:w-full">
            <h1 class="text-4xl font-semibold mb-4 mdmax:text-2xl mdmax:text-center">Transformasi Digital</h1>
            <div class="mb-4">
              Institusi pemerintah saat ini perlu bertransformasi dengan kemajuan teknologi untuk menopang setiap prose yang berlangsung. G2 Academy akan menjadi mitra pemerintah dalam melakukan
              transformasi digital dalam praktik dan administrasi di kantor (internal) maupun pelayanan publik (eksternal).
            </div>
            <div class="text-base font-semibold">Manfaat bagi Pemerintah:</div>
            <ol class="list-decimal pl-4 ml-4">
              <li>Memaksimalkan proses birokrasi secara digital</li>
              <li>Menciptakan iklim dan budaya teknologi dalam mendukung efektifitas dan efisiensi proses</li>
              <li>Mengembangkan kemampuan pimpinan, staff dan karyawan dalam mengembangkan diri sebagai adaptasi Industri 4.0</li>
              <li>Mendorong terciptanya alur kordinasi dan simpul komunikasi yang baik secara sistem, baik tingkat pusat, provinsi maupun daerah</li>
              <li>Mempermudah dan mempercepat layanan publik</li>
            </ol>
          </div>
          <div class="w-2/5 flex justify-end mdmax:w-full mdmax:hidden">
            <image-item :source="require('@/assets/images/government_02.png')" />
          </div>
        </div>
        <div class="flex gap-10 items-center mb-20 mdmax:flex-col mdmax:mb-10">
          <div class="w-2/5 flex justify-start mdmax:hidden">
            <image-item :source="require('@/assets/images/government_03.png')" />
          </div>
          <div class="w-3/5 mdmax:w-full">
            <h1 class="text-4xl font-semibold mb-4 mdmax:text-2xl mdmax:text-center">Pelatihan & Pengembangan Profesional</h1>
            <div class="mb-4">
              Kemampuan IT merupakan keterampilan abad 21 yang harus dimiliki oleh setiap pekerja. Untuk itu, G2 Academy mendukung setiap pekerja di pemerintahan untuk mengembangkan diri secara khusus
              dalam dunia IT, seiring fokus utama pemerintah dan G2 adalah pengembangan sumber daya manusia yang unggul.
            </div>
            <div class="text-base font-semibold">Manfaat bagi Pemerintah:</div>
            <ol class="list-decimal pl-4 ml-4">
              <li>Menemukan potensi-potensi pekerja yang terampil dalam bidang IT</li>
              <li>Mengembangkan dan memperkaya wawasan IT bagi setiap pekerja yang berada di bawah naungan pemerintah.</li>
              <li>Terciptanya budaya kerja berbasis IT yang terarah dan terukur.</li>
              <li>Meningkatkan penatalayanan terhadap masyarakat, baik secara kuantitas maupun kualitas</li>
            </ol>
          </div>
        </div>
        <div class="flex gap-10 items-center mb-10 mdmax:flex-col">
          <div class="w-3/5 mdmax:w-full">
            <h1 class="text-4xl font-semibold mb-4 mdmax:text-2xl mdmax:text-center">Proyek & Infrastruktur</h1>
            <div class="mb-4">Mendukung pemerintah dalam melakukan pengembangan infrastruktur secara khusus dalam bidang IT.</div>
            <div class="text-base font-semibold">Manfaat bagi Pemerintah:</div>
            <ol class="list-decimal pl-4 ml-4">
              <li>Tersedianya tenaga ahli yang mampu menghasilkan produk yang bermutu dan berkualitas, berdasarkan pengalaman yang dimiliki.</li>
              <li>Terlaksananya program-program pemerintah yang berbasis digital</li>
              <li>Menciptkan budaya kolaborasi antara pemerintah dengan swasta dalam membangun produk yang dapat dimanfaatkan secara luas.</li>
              <li>G2 Academy juga menjadi representasi masyarakat dalam mengemukakan gagasan/input yang bermanfaat bagi pemerintah</li>
            </ol>
          </div>
          <div class="w-2/5 flex justify-end mdmax:hidden">
            <image-item :source="require('@/assets/images/government_04.png')" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem')
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  }
}
</script>
<style lang="scss" scoped>
.government {
  &__header {
    background-image: url('~@/assets/images/government_01.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #888888;
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      top: 0;
      left: 0;
    }
  }
}
</style>
